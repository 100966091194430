@media only screen and (max-width: 600px) and (min-width: 300px) {
    .about_us_div_flex{
        display: block;
    }
    .about_us_box{
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 2rem;
        height: auto;
    }
    .about_us_video_div{
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 2rem;
    }
    .about_us_box_two{
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        // margin-bottom: 2rem;
        height: auto;

    }
    .about_us_ratio_show{
        display: block;
    }
    .divided_about_us_screen{
        width: 100%;
    }
    .about_us_div_flex_low{
        grid-template-columns: 1fr;
    }
    .box_style_modal_video{
        width: 90%;
    }
    .about_us_ratio{
        padding: 0;
    }
    // .about_us_divider_hide{
    //     display: none;
    // }
}
@media only screen and (max-width: 800px) and (min-width: 601px) {
    .about_us_div_flex{
        display: block;
    }
    .about_us_box{
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 2rem;
        height: auto;
    }
    .about_us_video_div{
        margin-bottom: 2rem;
    }
    .about_us_box_two{
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        // margin-bottom: 2rem;
        height: auto;
    }
    .about_us_ratio_show{
        display: block;
    }
    .divided_about_us_screen{
        width: 100%;
    }
    .about_us_div_flex_low{
        grid-template-columns: 1fr 1fr;
    }
    .box_style_modal_video{
        width: 90%;
    }
    .about_us_ratio{
        padding: 0;
    }
    // .about_us_divider_hide{
    //     display: none;
    // }
}