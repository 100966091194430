@media only screen and (max-width: 700px) and (min-width: 300px) {
  .contact_info {
    display: block;
    margin: 0;
  }
  .contact_info_detail {
    position: relative;
    width: 100%;
  }
  .contact_input_field {
    display: none;
  }
  .contact_divided {
    position: relative;
    left: 0;
    width: 100%;
    height: auto;
  }
  .input_contect_form {
    width: 100%;
  }
  .input_contect_form {
    margin-left: 0;
  }
  .space_contact_show {
    display: block;
    width: 100%;
    text-align: center;
  }
  .contact_areatext {
    width: 70%;
  }
  .icon_para_input {
    text-align: center;
  }
  .contact_margin_space {
    margin-bottom: 2rem;
  }
  .contact_buttom_center {
    text-align: center;
  }
  .contact_button {
    width: 50%;
  }
  .text_field_contact {
    width: 70%;
  }
}
@media only screen and (max-width: 900px) and (min-width: 701px) {
  .contact_info {
    display: block;
    margin: 0;
  }
  .contact_info_detail {
    position: relative;
    width: 100%;
  }
  .contact_input_field {
    display: none;
  }
  .contact_divided {
    position: relative;
    left: 0;
    width: 100%;
    height: auto;
  }
  .input_contect_form{
    width: 100%;
    margin-left: 0;
    text-align: center;
  }
  .contact_button {
    width: 30%;
  }
  .space_contact_show{
    width: 100%;
    justify-content: center;
  }
  .text_field_contact{
    margin-right: 1rem !important;
  }
  .contact_areatext{
    margin-right: 1rem;
    width: 527px;
  }
}
