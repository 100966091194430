@media only screen and (max-width: 600px) and (min-width: 300px) {
  .cabel_downloade {
    grid-template-columns: 1fr;
  }
  .cabel_div_main {
    grid-template-columns: 1fr;
  }
  .cabel_space_border {
    justify-content: center;
  }
  .cabel_specification {
    margin-left: 5px;
  }
  .cabel_box_style {
    width: 95%;
  }
  .cabel_downloade_modal {
    width: 100%;
  }
  .product_showimage {
    grid-template-columns: 1fr;
  }
  .product_show_part_two {
    padding: 20px 0;
  }
  .product_show_part_one {
    padding: 0;
  }
}
@media only screen and (max-width: 800px) and (min-width: 601px) {
  .cabel_downloade {
    grid-template-columns: 1fr 1fr;
  }
  .cabel_div_main {
    grid-template-columns: 1fr 1fr;
  }
  .cabel_space_border {
    justify-content: center;
  }
  .cabel_specification {
    margin-left: 5px;
  }
  .cabel_box_style {
    width: 75%;
  }
  .cabel_downloade_modal {
    width: 100%;
  }
  .product_showimage {
    grid-template-columns: 1fr;
  }
 
}
@media only screen and (max-width: 1100px) and (min-width: 801px) {
  .cabel_downloade {
    grid-template-columns: repeat(3, 1fr);
  }
  .cabel_div_main {
    grid-template-columns: repeat(3, 1fr);
  }
  .product_showimage {
    grid-template-columns: 60% 40%;
  }
}
