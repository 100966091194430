@media only screen and (max-width: 600px) and (min-width: 300px)  {
    .footerdivided{
        grid-template-columns: 1fr;
    }
    .footerlink{
        width: 100%;
        margin-left: 0;
    }
    .footer_social_icon {
        display: block;
    }
    .footer_social_icon_space{
        width: 100%;
        justify-content: center;
        margin-top: 1rem;
    }
    .power_para{
        margin-top: 1rem;
        text-align: center;
    }
}
@media only screen and (max-width: 800px) and (min-width: 601px)  {
    .footerdivided{
        grid-template-columns: 1fr 1fr;
    }
    .footerlink{
        width: 100%;
        margin-left: 0;
    }
}