@media only screen and (max-width: 640px) and (min-width: 300px) {
  .blog_card_view {
    grid-template-columns: 1fr;
  }
  .card_blog_div {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .blog_div {
    margin: 0;
  }
  .input_blog {
    width: 230px;
  }
  .blog_search_bg {
    width: 80px;
  }
  .select_show {
    padding: 0;
  }
  .select_blog {
    // margin-bottom: 3rem;
    width: 68%;
  }
  .blog_top_show{
    grid-template-columns: 1fr;
  }
  .mobile_div_search{
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
  .blog_search_div {
    width: 312px;
    // left: 18%;
    // top: 50%;
    z-index: 55
  }
  .read_more_blog{
    width: 30%;
  }
  .blog_paragraph {
    font-size: 0.9375rem;
    color: #333;
    overflow: hidden !important;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    max-height: 190px;
  }
}
@media only screen and (max-width: 992px) and (min-width: 641px) {
  .blog_card_view {
    grid-template-columns: 60% 40%;
  }
  // .search_icon_blog {
  //   display: block;
  //   background-color: transparent;
  //   width: 30px;
  //   height: 40px;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   border: 1px solid #09c3d0;
  //   cursor: pointer;
  //   font-weight: 600;
  //   color: #09c3d0;
  //   font-size: 14px;
  //   &:hover {
  //     background-color: #09c3d0;
  //     color: #fff;
  //   }
  // }
  // .blog_search_bg {
  //   display: none;
  // }
  .select_show {
    padding: 10px;
  }
}
@media only screen and (max-width: 1180px) and (min-width: 993px) {
  .read_more_blog_1{
    width: 40%;
  }
}
