.faq_style {
  display: grid;
  grid-template-columns: 60% 40%;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.faq_qus {
  padding: 20px;
  background-color: #f5f5f5;
  color: #000;
  cursor: pointer;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  &:hover {
    color: #d83030;
  }
}
.faq_change {
  padding: 20px;
  background-color: #d83030;
  color: #fff;
  cursor: pointer;
  margin-bottom: 1rem;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    color: #fff;
  }
}
.faq_ans {
  padding: 20px;
  color: #676767;
  margin-bottom: 1rem;
  display: none;
}
.faq_ans.active {
  display: block;
}
.faq_q_para {
  font-size: 19px;
  font-weight: 700;
  line-height: 30px;
  margin: 0;
  p {
    margin: 0;
  }
}
.faq_error_show {
  color: #d83030;
  font-size: 10px;
  padding: 2px 2px;
  margin: 0;
}
.faq_ask {
  background-color: #252628;
  height: auto;
  margin: 1rem;
  padding: 3rem;
  border-radius: 5px;
}
.faq_qa {
  margin: 1rem;
}
.faq_give_q_p {
  color: #d83030;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 30px;
  letter-spacing: 1px;
  margin-bottom: 14px;
  text-align: left;
}
.faq_give_h {
  color: #fff;
  color: #fff;
  font-size: 33px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
}
.faq_textfield {
  .css-i4bv87-MuiSvgIcon-root {
    color: #d83030;
    font-size: 20px;
  }
  .css-vubbuv {
    color: #d83030;
    font-size: 20px;
  }
  .css-1x51dt5-MuiInputBase-input-MuiInput-input {
    color: #cccccc;
    font-size: 14px;
  }
  .css-mqt2s5 {
    color: #cccccc;
    font-size: 14px;
  }
  .css-mnn31 {
    color: #cccccc;
    font-size: 14px;
  }
  .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
    color: #cccccc;
    font-size: 14px;
  }
  .css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: #d83030;
  }
  .css-v4u5dn-MuiInputBase-root-MuiInput-root:after {
    border-bottom: 1px solid #d83030;
  }
  .css-zrqpq1::after {
    border-bottom: 1px solid #d83030;
  }
  .css-zrqpq1.Mui-focused::after {
    border-bottom: 1px solid #d83030;
  }
  .css-348fk2.Mui-focused {
    color: #d83030;

  }

  .css-v4u5dn-MuiInputBase-root-MuiInput-root:before {
    border-bottom: 1px solid #3b3c3f;
  }
  .css-zrqpq1::before {
    border-bottom: 1px solid #3b3c3f;
  }
  .css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {
    color: #cccccc;
  }
  .css-v4u5dn-MuiInputBase-root-MuiInput-root:hover:not(
      .Mui-disabled,
      .Mui-error
    ):before {
    border-bottom: 1px solid #3b3c3f;
  }
  .css-zrqpq1:hover:not(.Mui-disabled, .Mui-error)::before {
    border-bottom: 1px solid #3b3c3f;
  }
}
.faq_text_size {
  font-size: 14px;
}
.faq_submit_btn {
  padding: 10px;
  width: 40%;
  outline: none;
  border: none;
  background-color: #235789;
  color: #fff;
  border-radius: 5px;
  &:hover {
    background-color: #d83030;
  }
}
.faq_btn_space {
  margin-top: 2rem;
}

@media only screen and (max-width: 600px) and (min-width: 300px) {
  .faq_style {
    grid-template-columns: 1fr;
  }
  .faq_q_para {
    font-size: 16px;
  }
}
@media only screen and (max-width: 800px) and (min-width: 601px) {
  .faq_style {
    grid-template-columns: 1fr;
  }
}
