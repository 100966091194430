.blog_div {
  height: auto;
  margin: 2rem 0;
}
.blog_search {
  display: flex;
  // padding: 24px;
  background-color: #f6f3ee;
  justify-content: center;
  // width: 50%;
}
.blog_search_bg {
  background-color: transparent;
  width: 120px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #09c3d0;
  cursor: pointer;
  font-weight: 600;
  color: #09c3d0;
  font-size: 14px;
  &:hover {
    background-color: #09c3d0;
    color: #fff;
  }
}
.input_blog {
  width: 430px;
  border-right: 0 !important;
  border: 1px solid #000;
}
.blog_card_view {
  display: grid;
  grid-template-columns: 70% 30%;
  margin-top: 3rem;
}
.card_blog_div {
  width: 100%;
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 2rem;
  // cursor: pointer;
}
.card_blog_div_detail {
  width: 100%;
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 2rem;
}
.blog_img_style {
  width: 100%;
}
.blog_heading {
  padding: 24px;
}
.blog_heading_para_head {
  font-size: 1.25rem;
  line-height: 1.75rem;
  // font-family: "univia-pro", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #333;
}
.blog_paragraph {
  font-size: 0.9375rem;
  color: #333;
  overflow: hidden !important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.blog_detail {
  font-size: 0.9375rem;
  color: #333;
  overflow: hidden;
}
.user_style {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 0.5rem;
}
.blog_span {
  // font-family: "univia-pro", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  font-size: 1rem;
  margin-left: 0;
  color: #333;
}
.blog_user_margin {
  margin-bottom: 8px;
}
.blog_date {
  color: #858585;
  font-size: 0.975rem;
  margin: 0;
}
.select_show {
  //   background-color: #F6F3EE;
  padding: 0 24px;
}
.select_blog {
  width: 60%;
  background-color: #f6f3ee;
  // margin-top: 3rem;
  // padding: 24px;
}
// .search_icon_blog {
//   display: none;
// }
.read_more_blog {
  padding: 15px;
  width: 15%;
  margin: 24px;
  background-color: #d83030;
  color: #fff;
  border: none;
  &:hover {
    background-color: #333;
  }
}
.read_more_blog_1 {
  padding: 15px;
  width: 30%;
  margin: 24px;
  background-color: #d83030;
  color: #fff;
  border: none;
  &:hover {
    background-color: #333;
  }
}
.blog_search_div {
  // height: 300px;
  background-color: #fff;
  padding: 1rem 10px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  width: 550px;
  position: absolute;
  // margin: 0 auto;
  // margin-top: -20px;
  // left: 11%;
}
.blog_top_show {
  display: grid;
  grid-template-columns: 1fr;
  padding: 24px;
  background-color: #f6f3ee;
  align-items: center;
  margin-top: 3rem;
}
.blog_div_top_search {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.blog_divided_div {
  display: flex;
  justify-content: center;
}
.blog_false {
  cursor: pointer;
}
.pagination-center {
  display: flex;
  justify-content: center;
  padding: 0.5rem 0;
}
