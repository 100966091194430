@media only screen and (max-width: 600px) and (min-width: 300px) {
  .headersearch {
    display: none;
  }
  .recommended_slide {
    margin-left: 0;
  }
  .contecticon {
    margin-left: 0;
  }
  .contecticon_hide {
    display: block;
  }
  .contecticon {
    display: none;
  }
  .header_bottom_color {
    display: none;
  }
  .contecticon_hide_color {
    display: flex;
    background-color: #09c3d0;
    height: 50px;
    align-items: center;
    width: 50px;
    justify-content: center;
  }
  .mobile_header_show {
    width: 100%;
    height: 100vh;
    background-color: #f0f0f0;
    display: block;
  }
  .header_mobile_responsive {
    background-color: #fff;
    display: block;

    width: 100%;
  }
  .para_text_style_mobile {
    // font-family: "univia-pro", sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 1.25rem;
    line-height: 1.5rem;
    position: relative;
    text-align: left;
    text-decoration: none;
    text-transform: none;
    padding: 25px 35px 20px;
    color: #000;
    margin: 0;
    border-bottom: 2px solid #d4d4d4;
    &::after {
      color: #09c3d0;
      color: var(--primary, #09c3d0);
      content: ">";
      display: block;
      // font-family: "univia-pro", sans-serif;
      font-size: 2rem;
      position: absolute;
      right: 35px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
  .para_text_style_mobile_link {
    // font-family: "univia-pro", sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 1.25rem;
    line-height: 1.5rem;
    position: relative;
    color: #000;
    text-align: left;
    text-decoration: none;
    text-transform: none;
    padding: 25px 35px 20px;
    border-bottom: 2px solid #d4d4d4;
    margin: 0;
    color: #09c3d0;
  }
  .box_style_modal {
    width: 90% !important;
  }
  .product_show.active {
    display: none;
  }
  .team_leader_mobile{
    padding: 2rem;
    width: 100%;
    display: block;
    overflow-y: auto;
    position: relative;
    z-index: 55;
    height: 100vh;
  }
  .team_show{
    grid-template-columns: 1fr;
  }
  .link_div_show{
    height: 40px;
    display: flex;
    align-items: center;
  }
  .url_link{
    margin: 0;
  }
  .email_error{
    width: 64% !important;
  }
}
@media only screen and (max-width: 700px) and (min-width: 600px) {
  .email_error{
    width: 64% !important;
  }
}
@media only screen and (max-width: 992px) and (min-width: 601px) {
  .header_bottom_color {
    display: none;
  }
  .contecticon_hide {
    display: block;
  }
  .contecticon_hide_color {
    display: flex;
    background-color: #09c3d0;
    height: 50px;
    align-items: center;
    width: 50px;
    justify-content: center;
  }
  .headersearch {
    display: none;
  }
  .mobile_header_show {
    width: 100%;
    height: 100vh;
    background-color: #f0f0f0;
    display: block;
  }
  .header_mobile_responsive {
    background-color: #fff;
    display: block;

    width: 100%;
  }
  .para_text_style_mobile {
    // font-family: "univia-pro", sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 1.25rem;
    line-height: 1.5rem;
    position: relative;
    text-align: left;
    color: #000;
    text-decoration: none;
    text-transform: none;
    padding: 25px 35px 20px;
    border-bottom: 2px solid #d4d4d4;
    margin: 0;
    &::after {
      color: #09c3d0;
      color: var(--primary, #09c3d0);
      content: ">";
      display: block;
      // font-family: "univia-pro", sans-serif;
      font-size: 2rem;
      position: absolute;
      right: 35px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
  .para_text_style_mobile_link {
    // font-family: "univia-pro", sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 1.25rem;
    line-height: 1.5rem;
    position: relative;
    color: #000;
    text-align: left;
    text-decoration: none;
    text-transform: none;
    padding: 25px 35px 20px;
    border-bottom: 2px solid #d4d4d4;
    margin: 0;
    color: #09c3d0;

  }
  .product_show.active {
    display: none;
  }
  .team_leader_mobile{
    padding: 2rem;
    overflow-y: scroll;
    width: 100%;
    display: block;
    height: 100vh;
  }
  .team_show{
    grid-template-columns: 1fr 1fr;
  }
  .team_photo_style{
    width: 250px;
    height: 300px;
  }
  .link_div_show{
    height: 40px;
    display: flex;
    align-items: center;
  }
  .url_link{
    margin: 0;
  }
}
