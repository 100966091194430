@media only screen and (max-width: 600px) and (min-width: 300px) {
  .banner_test_left_one {
    width: 100%;
    padding: 6rem 0rem 0rem 0rem;
  }
  .banner_test_left {
    display: none;
  }
  .banner_width {
    width: 100%;
  }
  .cardshowstyle {
    height: auto;
  }
  .takelook {
    display: block;
  }
  .boxstyle {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-top: 2rem;
  }
  .takelook_style {
    margin: 0 0 1rem 0;
  }
  .card_list_para {
    padding-left: 0;
  }
  .recamanded_text {
    padding-left: 0;
  }
  .list_div {
    display: block;
  }
  .recommended_slide {
    margin-left: 0;
    margin-right: 0;
  }
  .now_meet_one {
    font-size: 1.75rem;
  }
  .videoclip {
    height: 380px !important;
  }
  .video_play {
    height: 380px;
  }
  .about_choose_us_div {
    display: block;
  }
  .choose_partone {
    width: 100%;
  }
  .choose_parttwo {
    width: 100%;
  }
  .choose_partthree {
    width: 100%;
  }
  .banner_slide_mobile {
    display: block;
    text-align: center;
  }
  .banner_slide {
    display: none;
  }
  .recamanded_text {
    font-size: 2rem;
  }
  .takelook_style {
    font-size: 2rem;
  }
  // .about_us_now {
  //   font-size: 2rem;
  // }
  .banner_test_left_mobile {
    width: 100%;
    margin: 2rem 0rem 0rem 0rem;
    height: auto;
    display: block;
    padding-bottom: 2rem;
  }
  .banner_second_text_mobile {
    width: 100%;
    height: auto;
    display: flex;
    margin: 2rem 0rem 0rem 0rem;
    display: block;
    padding-bottom: 2rem;
  }
  .banner_second_text {
    display: none;
  }
  .now_meet {
    font-size: 2rem;
  }
  .banner_show_mobile {
    display: block;
  }
  .banner_not_show {
    display: none;
  }
  .recommended_list {
    max-width: 100%;
  }
  .mobile_search_style {
    display: flex;
    width: 80%;
    margin: 0 auto;
    padding-top: 2rem;
  }
  .search_show {
    display: none;
  }
  .search_name_padding {
    padding: 2rem 0.3rem;
    background-color: #fff;
    border: 1px solid #09c3d0;
    width: 80%;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 850px) and (min-width: 601px) {
  .banner_test_left_one {
    width: 65%;
  }
  .banner_test_left {
    width: 50%;
  }
  .banner_show_mobile {
    display: block;
  }
  .banner_not_show {
    display: none;
  }
  .banner_test_left_mobile {
    width: 100%;
    margin: 2rem 0rem 0rem 0rem;
    height: auto;
    display: block;
    padding-bottom: 2rem;
  }
  .banner_second_text_mobile {
    width: 100%;
    height: auto;
    display: flex;
    margin: 2rem 0rem 0rem 0rem;
    display: block;
    padding-bottom: 2rem;
  }
  .banner_second_text {
    display: none;
  }
  .banner_test_left {
    display: none;
  }
  .banner_width {
    width: 100%;
  }
  .about_choose_us_div {
    display: block;
  }
  .choose_partone {
    width: 100%;
  }
  .choose_parttwo {
    width: 100%;
  }
  .choose_partthree {
    width: 100%;
  }
  .cardshowstyle {
    height: auto;
  }
  .takelook {
    display: block;
  }
  .boxstyle {
    width: 400px;
    margin: 2rem auto;
  }
  .takelook_style {
    margin: 0 0 1rem 0;
  }
  .list_div {
    display: block;
  }
  .recommended_list {
    margin: 2rem auto;
  }
  .mobile_search_style {
    display: flex;
    width: 80%;
    margin: 0 auto;
    padding-top: 2rem;
  }
  .search_show {
    display: none;
  }
  .search_name_padding {
    padding: 2rem 0.3rem;
    background-color: #fff;
    border: 1px solid #09c3d0;
    width: 80%;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 730px) and (min-width: 601px) {
  .banner_slide_mobile {
    display: block;
    text-align: center;
  }
  .banner_slide {
    display: none;
  }
}

@media only screen and (max-width: 1000px) and (min-width: 851px) {
  .about_choose_us_div {
    display: block;
  }
  .choose_partone {
    width: 100%;
  }
  .choose_parttwo {
    width: 100%;
  }
  .choose_partthree {
    width: 100%;
  }
  .banner_width {
    width: 50%;
  }
  .banner_test_left_one {
    width: 50%;
  }
  // .banner_slide{
  //   display: block;
  // }
  .mobile_search_style {
    display: flex;
    width: 80%;
    margin: 0 auto;
    padding-top: 2rem;
  }
  .search_show {
    display: none;
  }
  .search_name_padding {
    padding: 2rem 0.3rem;
    background-color: #fff;
    border: 1px solid #09c3d0;
    width: 80%;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 1230px) and (min-width: 1001px) {
  .banner_test_left_one {
    width: 40%;
  }
  // .banner_slide{
  //   display: block;
  // }
}
