.headermain {
  background-color: #fff;
  height: auto;
  position: sticky;
  top: 0;
  z-index: 55;
}
.topheader {
  background-color: #fff;
  height: 50px;
}
.bottomheader {
  height: 40px;
  display: flex;
  align-items: center;
  width: 90%;
}
.divider {
  // padding: 0 30px 0 30px;
  .css-9mgopn-MuiDivider-root {
    border-color: #000;
  }
}
.headershow {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}
.headerlogo {
  // padding-left: 2rem;
  cursor: pointer;
}
.headersearch {
  display: flex;
  width: 460px;
}
.searchbox {
  height: 31px;
  width: 100%;
  border: 1px solid #09c3d0;
  border-right: 1px solid #fff;
  display: flex;
}
.searchinput {
  width: 100%;
  border: 1px solid #fff;
}
.headersearchicon {
  height: 31px;
  background-color: #09c3d0;
  align-items: center;
  width: 2.5rem;
  cursor: pointer;
  display: flex;
  .css-i4bv87-MuiSvgIcon-root {
    color: #fff;
    height: 1em;
    width: 1.5em;
  }
  .css-vubbuv {
    color: #fff;
    height: 1em;
    width: 1.5em;
  }
}
.headerproduct {
  font-size: 1rem;
  font-weight: 300;
  margin-left: 1rem;
  margin-right: 1rem;
  color: #fff;
}
.headerproduct:hover {
  border-bottom: 5px solid #fff;
  cursor: pointer;
}
.headerproduct.active {
  border-bottom: 5px solid #fff;
  cursor: pointer;
}
.contecticon {
  cursor: pointer;
  .css-i4bv87-MuiSvgIcon-root {
    color: #fff;
  }
  .css-vubbuv {
    color: #fff;
  }
}
.header_bottom_color {
  background-color: #023047;
}
.contecticon_hide {
  // margin-left: 4rem;
  cursor: pointer;
  .css-i4bv87-MuiSvgIcon-root {
    color: #09c3d0;
  }
  .css-vubbuv {
    color: #09c3d0;
  }
}
.contecticon_hide_color {
  display: none;
  .css-i4bv87-MuiSvgIcon-root {
    color: #fff;
  }
  .css-vubbuv {
    color: #fff;
  }
}
.headercolor {
  color: #fff;
  font-size: 12px;
}
.iconcenter {
  text-align: center;
  .css-i4bv87-MuiSvgIcon-root {
    font-size: 16px;
  }
}
.iconcenter_hide {
  text-align: center;
  .css-i4bv87-MuiSvgIcon-root {
    font-size: 2.5rem;
  }
  .css-vubbuv {
    font-size: 2.5rem;
  }
}
.product_show {
  background-color: #fff;
  width: 95%;
  height: auto;
  display: none;
  margin: 0 auto;
  min-height: 450px;
}
.product_show.active {
  display: block;
}
.product_part {
  width: 100%;
  display: flex;
}
.part_one {
  width: 25%;
  background-color: #e1e1e1;
  height: 450px;
  overflow-y: scroll;
}
.part_two {
  width: 75%;
  background-color: #c0c0c0;
  height: 450px;
  overflow-y: scroll;
  padding: 2rem;
}
.product_show_list {
  padding: 1rem;
  display: flex;
  justify-content: center;
}
.product_details_style {
  color: #075264;
  margin: 0;
  border-bottom: 4px solid #075264;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  text-align: center;
  width: auto;
}
.pro_show {
  background: transparent;
  border: 0;
  color: #636363;
  // font-family: "univia-pro", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 1rem;
  padding: 0;
  text-align: left;
  width: 100%;
  position: relative;
  cursor: pointer;
  &:hover {
    color: #075264;
  }
}
.underline {
  border-bottom: 1px solid #075264;
  color: #075264;
  &::after {
    content: ">";
    position: absolute;
    right: 0px;
    bottom: 50%;
    -webkit-transform: translateY(11px);
    -ms-transform: translateY(11px);
    transform: translateY(11px);
    color: #075264;
    font-size: 1.2rem;
    line-height: 1.5rem;
    vertical-align: middle;
  }
}
.pro_div_style {
  padding: 1rem 1rem 1rem 2rem;
}
.link_style {
  text-decoration: none;
  width: fit-content;
}
.link_style_2 {
  text-decoration: none;
  display: flex;
  align-items: center;
}
.product_details_show {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
}
.product_divided_show {
  width: 75%;
  padding-right: 20px;
  padding-left: 20px;
  h5 {
    color: #075264;
    font-size: 1.25rem;
    line-height: 28px;
    line-height: 1.75rem;
    // font-family: "univia-pro", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  .para_show {
    color: #075264;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
.product_divided_show_two {
  width: 25%;
  padding-left: 20px;
  padding-right: 20px;
}
.bottom_details_show {
  width: 100%;
  padding: 20px;
  // max-width: 50%;
  .text_color_para {
    color: #075264;
    // font-family: "univia-pro", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1.25rem;
  }
  p {
    color: #000;
    font-weight: 300;
    font-style: normal;
    margin-bottom: 10px;
    font-size: 15px;
    width: fit-content;
  }
}
.contecticon_hide {
  display: none;
}
.header_contact {
  display: flex;
  justify-content: flex-end;
  width: 10%;
}
.header_div_contact {
  display: flex;
  width: 100%;
}
.mobile_header_show {
  display: none;
}
.team_show {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.team_photo {
  width: 100%;
  text-align: center;
  padding: 20px;
}
.team_photo_style {
  width: 280px;
  height: 330px;
}
.team_para {
  color: #032e42;
  font-size: 19px;
  margin: 0;
}
.para_space_team {
  padding: 1rem;
}
.team_post {
  color: #707070;
  font-size: 13px;
}
.team_leader_mobile {
  display: none;
}
.search_show {
  background-color: #fff;
  width: 460px;
  position: absolute;
  // right: 55px;
  top: 42px;
  border: 1px solid #09c3d0;
  z-index: 55;
}
.search_name_padding {
  padding: 2rem 1rem;
}
.url_link {
  font-size: 16px;
  &:hover {
    color: #023047;
    text-decoration: underline;
    cursor: pointer;
    width: fit-content;
  }
}
.link_div_show{
  width: fit-content;
}
.mobile_search_style{
  display: none;
}
.search_header-div{
  display: flex;
  justify-content: end;
}
.header_download_div{
  display: grid;
  grid-template-columns: 1fr 1fr;
}