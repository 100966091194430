.footermain {
  width: 100%;
  // height: 500px;
  background-color: #023047;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.footerdivided {
  display: grid;
  border-bottom: 1px solid #fff;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
}
.footerlink {
  width: fit-content;
}
.linkParatext {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
}
.linkParatext_two {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 2rem;
  margin: 0;
  // cursor: pointer;
  width: 25%;
}
.footerdivider {
  margin: 2rem 2rem 2rem 2rem;
  .css-9mgopn-MuiDivider-root {
    border-color: #fff;
  }
}
.linkparatext2 {
  color: #fff;
  font-size: 1rem;
  font-weight: 300;
  cursor: pointer;
  margin-bottom: 0.8rem;
}
.linkparatext2:hover {
  text-decoration: underline;
}
.footerlowertext {
  color: #fff;
  font-size: 1rem;
  font-weight: 300;
  margin: 0;
}
.footer_social_icon {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.footer_social_icon_space {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .css-i4bv87-MuiSvgIcon-root {
    font-size: 1.8rem;
    color: #fff;
    cursor: pointer;
  }
  .css-vubbuv {
    font-size: 1.8rem;
    color: #fff;
    cursor: pointer;
  }
}
.social_icon_space_footer {
  margin-right: 2rem;
}
.redical_footer{
  padding: 0 24px;
  display: flex;
  justify-content: flex-end;
}
.power_para{
  color: #fff;
  margin: 0;
  width: fit-content;
}
