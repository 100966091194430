.product_main {
  padding-bottom: 2rem;
  height: auto;
}
.product_bg {
  background-color: #fff;
  margin-bottom: 2rem;
  width: auto;
  display: flex;
  // height: 80px;
  align-items: center;
  justify-content: center;
  padding: 25px;
}
.product_bg_image {
  height: 240px;
}
.cable_image_show {
  width: 100%;
  height: 140px;
}
.cabel_div {
  width: 140px;
  height: 140px;
  margin: 0 auto;
}
.cabel_div_main {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}
.cabel_div_show_ {
  padding: 0 10px 12px 10px;
}
.cabel_divided_div {
  text-align: center;
  padding: 20px 0 0;
  background-color: #fff;
  height: auto;
  border: 1px solid #ccc;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
}
.cabel_margin {
  border-bottom: 1px solid #333;
  height: auto;
  margin-top: 35px;
  // min-height: 280px;
  padding-top: 25px;
  padding-bottom: 25px;
}
.cabel_name_ {
  color: #4a4a4a;
  // font-family: "univia-pro", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 18px;
  line-height: 1.125rem;
  text-align: center;
  margin-bottom: 14px;
  margin-top: 10px;
}
.cabel_product_name {
  padding: 10px;
  background-color: #ececeb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.cabel_name_para {
  margin: 0;
}
.open_cabel_div {
  padding: 5px;
  text-align: start;
}
.open_cabel_para {
  margin: 0;
  color: #09c3d0;
  font-size: 0.9375rem;
  padding: 3px 0;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.cabel_downloade {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  // margin-top: 2rem;
}
.cabel_downloade_box {
  height: auto;
  padding: 0 10px 12px 10px;
}
.cabel_down_ {
  height: auto;
  border: 1px solid #d4d4d4;
  padding: 20px;
  &:hover {
    box-shadow: 0 4px 10px 0 rgba(52, 58, 64, 0.4);
  }
}
.cabel_downloade_div {
  width: 100%;
  margin-bottom: 20px;
}
.cabel_base_para {
  color: #007b37;
  margin-bottom: 5px;
}
.cabel_product_name_down {
  color: #000;
  text-decoration: underline;
  text-decoration-color: #0039c1;
  font-weight: 700;
  text-decoration-skip-ink: none;
  text-decoration-thickness: 3px;
  transition: all 200ms ease;
  display: inline-block;
  width: auto;
}
.cabel_product_detail {
  color: #333;
  // font-family: "roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  overflow-x: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0;
}
.cabel_down_space {
  margin-top: 3rem;
}
.cabel_space_border {
  display: flex;
  justify-content: center;
  border: 1px solid #333;
  align-items: center;
  height: 40px;
  cursor: pointer;
  .css-i4bv87-MuiSvgIcon-root {
    color: #0039c1;
    margin-right: 10px;
  }
  .css-vubbuv {
    color: #0039c1;
    margin-right: 10px;
  }
}
.cabel_specification {
  margin: 0;
}
.cabel_box_style {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0px 4px 10px 0;
  padding: 20px;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cabel_downloade_modal {
  display: flex;
  // justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 80%;
  padding: 20px 0;
}
.cabel_dowlnload_button {
  width: 100%;
  padding: 10px;
  background-color: #023047;
  color: #fff;
  margin-top: 2rem;
  border: 2px solid #023047;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: #0039c1;
    border: 2px solid #0039c1;
  }
}
.cabel_down_heading {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
}
.product_detail {
  margin-bottom: 3rem;
}
.h2_cabel {
  padding: 3rem 1rem;
  h2 {
    color: #333;
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 46px;
    line-height: 2.875rem;
    // font-family: "univia-pro", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
}
.product_showimage {
  display: grid;
  grid-template-columns: 65% 35%;
  margin-bottom: 2rem;
  border-bottom: 1px solid #d4d4d4;
}
.product_show_part_one {
  background-color: #fff;
  height: auto;
  padding: 20px;
}
.product_show_part_two {
  background-color: #fff;
  height: auto;
  padding: 0 20px;
  .heading {
    border-bottom: 1px solid #d4d4d4;
    margin: 0 0 10px;
    h1 {
      display: block;
      color: #0039c1;
      color: var(--primary, #0039c1);
      // font-family: "univia-pro", sans-serif;
      font-weight: 300;
      font-style: normal;
      font-size: 35px;
      font-size: 2.1875rem;
      line-height: 42px;
      line-height: 2.625rem;
      margin: 0 0 10px;
      padding: 0;
      border-bottom: 1px solid #d4d4d4;
    }
    p {
      // font-family: "roboto", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 16px;
      font-size: 1rem;
      line-height: 24px;
      line-height: 1.5rem;
      // margin: 0;
    }
  }
}
.ak {
  text-decoration: none;
  font-size: 16px;
  color: #0039c1;
}
.cabel_space_border_two {
  display: flex;
  justify-content: center;
  border: 1px solid #333;
  align-items: center;
  height: 40px;
  width: 50%;
  cursor: pointer;
  margin-bottom: 2rem;
  &:hover {
    background-color: #0039c1;
    color: #fff;
    .css-vubbuv {
      color: #fff;
      margin-right: 10px;
    }
    .css-i4bv87-MuiSvgIcon-root {
      color: #fff;
      margin-right: 10px;
    }
  }
  .css-i4bv87-MuiSvgIcon-root {
    color: #0039c1;
    margin-right: 10px;
  }
  .css-vubbuv {
    color: #0039c1;
    margin-right: 10px;
  }
}
.product_specification {
  // font-family: "univia-pro", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 0.9375rem;
  line-height: 1.125rem;
  margin: 0 0 10px;
  padding: 0;
}
.product_image_slide {
  text-align: center;
}
.image_product_array {
  width: 100px;
  height: 100px;
  padding: 10px;
  cursor: pointer;
}
.product_image_slide_center {
  margin-top: 2rem;
  text-align: center;
}
.product_show_main {
  height: auto;
  margin-bottom: 2rem;
  padding-top: 2rem;
}
.show_pro_img {
  height: 300px;
  width: 300px;
  object-fit: contain;
}
.pro_description {
  font-weight: 700;
  font-size: 20px;
  text-decoration: underline;
  text-decoration-color: #0039c1;
  color: #0039c1;
}
.email_error {
  font-size: 10px;
  text-align: start;
  color: #b80000;
  margin: 0;
  width: 85%;
}
