@import "header.scss";
@import "footer.scss";
@import "home.scss";
@import "about_us.scss";
@import "contact_us.scss";
@import "footer_responsive.scss";
@import "header_responsive.scss";
@import "home_responsive.scss";
// @import "react-slideshow-image/dist/styles.css";
@import "react-toastify/dist/ReactToastify.css";

@import "contact_responsive.scss";
@import "about_us_responsive.scss";
@import "blog.scss";
@import "blog_responsive.scss";
@import "faq.scss";
@import "product.scss";
@import "product_responsive.scss";

.videoclip {
  height: 500px;
  width: 100%;
}
.box_style_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  border: 2px solid #fff;
  box-shadow: 24;
  background-color: #fff;
  width: 50%;
}
.page404 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 700px;
  font-size: 40px;
  font-weight: 800;
  font-style: normal;
}
.page404_s{
  height: 700px;
}
.d404_divided{
  text-align: center;
}
