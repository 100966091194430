.banner {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 500px;
  position: relative;
  overflow: hidden;
}
.banner_test_left {
  width: 30%;
  margin: 9rem 3rem 3rem 2rem;
  height: 390px;
  display: block;
}
.banner_test_left_mobile {
  display: none;
}
.banner_test_left_one {
  width: 30%;
  padding: 6rem 0rem 0rem 2rem;
  height: 390px;
  position: relative;
  z-index: 1;
}
.now_meet {
  color: #333;
  font-size: 3rem;
  font-weight: 300;
  font-style: normal;
  margin: 0;
}
.now_meet_one {
  color: #fff;
  font-size: 3rem;
  font-weight: 300;
  font-style: normal;
  margin: 0;
}
.banner_two {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 500px;
  position: relative;
  overflow: hidden;
}
.cardshowstyle {
  padding: 2rem 0 2rem 0;
  height: auto;
  background-color: #f8f9fa;
}
.takelook {
  display: flex;
  justify-content: space-between;
}
.boxstyle {
  height: 330px;
  width: 28%;
  background-color: #fff;
  margin-right: 20px;
  margin-left: 20px;
  box-shadow: 0 4px 10px 0 rgba(52, 58, 64, 0.4);
  -webkit-transition: box-shadow 0.2s ease;
  transition: box-shadow 0.2s ease;
  transition: transform 0.2s;
  cursor: pointer;
}
.boxstyle:hover {
  transform: scale(1.05);
  background-color: #d3dcf2;
}
.takelook_style {
  color: #333;
  font-size: 2.25rem;
  font-weight: 300;
  margin: 0 0 2rem 1rem;
}
.card_image {
  width: 100%;
  height: 200px;
}
.card_text {
  padding: 1rem;
}
.card_text_line {
  font-size: 1.125rem;
  font-weight: 700;
  margin: auto;
  color: #333;
  text-decoration-skip-ink: none;
  text-decoration: underline;
  text-decoration-color: #09c3d0;
  text-decoration-thickness: 3px;
}
.card_list {
  background-color: #fff;
  padding: 2rem 0 2rem 0;
}
.card_list_text {
  margin-top: 2rem;
}
.card_list_para {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2rem;
  margin: 0;
  font-style: normal;
  padding-left: 15px;
}
.array_card {
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.recamanded_text {
  font-size: 2.25rem;
  margin: auto;
  font-weight: 300;
  color: #333;
  font-style: normal;
  padding-left: 15px;
}
.recommended_list {
  display: flex;
  margin-top: 2rem;
  // width: 400px;
  max-width: 400px;
}
.recommended_slide {
  width: 100%;
  height: auto;
  padding-bottom: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}
.recamanded_card {
  margin: 1rem 0;
  font-size: 1.25rem;
  font-weight: 700;
  color: #09c3d0;
}
.recamanded_card_text {
  font-size: 1rem;
  font-weight: 400;
  color: #333;
  font-style: normal;
  margin: auto;
}
.reademore {
  font-weight: 700;
  font-style: normal;
  font-size: 0.875rem;
  color: #09c3d0;
  margin: 1rem 0;
  cursor: pointer;
}
.read {
  display: flex;
  align-items: center;
  .css-i4bv87-MuiSvgIcon-root {
    font-size: 1rem;
    color: #09c3d0;
    margin-left: 5px;
    margin-top: 3px;
  }
  .css-vubbuv {
    font-size: 1rem;
    color: #09c3d0;
    margin-left: 5px;
    margin-top: 3px;
  }
}
.list_div {
  display: flex;
  justify-content: space-around;
}
.ourmissin_text {
  font-weight: 600;
  color: #333;
  font-size: 1.125rem;
  font-style: normal;
  line-height: 1.5rem;
}
.our_misson_para {
  color: #333;
  line-height: 22px;
}
.our_misson_para_one {
  color: #fff;
  line-height: 22px;
}
.homepage_button {
  background-color: #fff;
  border-radius: 0;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  font-size: 0.9375rem;
  line-height: 1;
  padding: 14px 10px;
  text-align: center;
  border-color: #09c3d0;
  color: #09c3d0;
  font-weight: 700;
  font-style: normal;
}
.homepage_button:hover {
  color: #fff;
  background-color: #09c3d0;
}
.homepage_button_two {
  background-color: transparent;
  border-radius: 0;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  font-size: 0.9375rem;
  line-height: 1;
  padding: 14px 10px;
  text-align: center;
  border-color: #fff;
  color: #fff;
  font-weight: 700;
  font-style: normal;
  &:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }
}
.banner_second_text {
  width: 100%;
  height: 200px;
  justify-content: flex-end;
  display: flex;
  margin: 9rem 2rem 2rem 0rem;
}
.banner_second_text_mobile {
  display: none;
}
.video_play {
  width: 100%;
  height: 500px;
  position: absolute;
  object-fit: cover;
}
.banner_width {
  width: 30%;
}
.banner_slide {
  text-align: center;
  display: block;
}
.banner_slide_mobile {
  display: none;
}
.banner_show_mobile {
  display: none;
}
.banner_not_show {
  display: block;
}
.scrool_image_home {
  width: 90%;
  height: 125px;
}
