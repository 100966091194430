.about_us_style {
  color: #fff;
  font-size: 3rem;
  line-height: 3.375rem;
  // font-family: "univia-pro", sans-serif;
  font-weight: 300;
  font-style: normal;
  margin: 0;
  padding-left: 20px;
}
.about_us_now {
  text-align: center;
  color: #333;
  // font-family: "univia-pro", sans-serif;
  font-style: normal;
  // width: 50%;
  // margin: auto;
}
.about_us_para {
  text-align: center;
  // font-family: "univia-pro", sans-serif;
  font-weight: 400;
  font-style: normal;
  // width: 50%;
  // margin: auto;
}
.about_us_how {
  text-align: center;
  color: #333;
  font-size: 1.25rem;
  line-height: 1.75rem;
  // font-family: "univia-pro", sans-serif;
  font-weight: 700;
  font-style: normal;
  margin: 0;
}
.about_us_note {
  text-align: center;
  color: #333;
  font-size: 1.75rem;
  line-height: 2.25rem;
  // font-family: "univia-pro", sans-serif;
  font-weight: 500;
  font-style: normal;
  margin-bottom: 3rem;
}
.about_us_div_flex {
  display: flex;
  margin-bottom: 3rem;
}
.about_us_box {
  width: 30%;
  height: 200px;
  background-color: #fff;
  margin-left: 20px;
  margin-right: 20px;
  border: 0;
  box-shadow: 0 4px 10px 0 rgba(52, 58, 64, 0.4);
  -webkit-transition: box-shadow 0.2s ease;
  transition: box-shadow 0.2s ease;
  padding: 24px;
  cursor: pointer;
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(52, 58, 64, 0.4);
  }
}
.about_us_div_box_show {
  padding: 2rem;
  background-color: #f8f9fa;
}
.about_us_box_div_para {
  text-align: center;
  font-size: 1.25rem;
  font-weight: 500;
  font-style: normal;
  margin: 0;
}
.about_us_box_div_para_one {
  font-size: 1.25rem;
  font-weight: 500;
  font-style: normal;
  margin: 0;
  padding: 1rem 0 0 0;
}
.about_us_box_div_para1 {
  text-align: center;
  font-weight: 400;
  font-style: normal;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  margin-top: 1rem;
}
.about_us_box_div_para2 {
  font-weight: 400;
  font-style: normal;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  margin: 0;
}
.img_about {
  width: 100%;
}
.img_about_1 {
  width: 100%;
  height: 200px;
}
.about_us_brand {
  padding: 2rem;
}
.about_us_video_div {
  width: 90%;
  height: auto;
  background-color: #fff;
  margin-left: 20px;
  margin-right: 20px;
  border: 0;
  box-shadow: 0 4px 10px 0 rgba(52, 58, 64, 0.4);
  -webkit-transition: box-shadow 0.2s ease;
  transition: box-shadow 0.2s ease;
  cursor: pointer;
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(52, 58, 64, 0.4);
  }
}
.about_us_video_icon {
  top: -130px;
  position: relative;
  left: 0;
  text-align: center;
  cursor: pointer;
  .css-i4bv87-MuiSvgIcon-root {
    font-size: 4rem;
    color: #225768;
    &:hover {
      color: #333;
    }
  }
  .css-vubbuv{
    font-size: 4rem;
    color: #225768;
    &:hover {
      color: #333;
    }
  }
}
.about_us_video_para {
  margin-top: -5rem;
  padding: 20px;
}
.box_style_modal_video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  border: 2px solid #fff;
  box-shadow: 24;
  background-color: #fff;
  width: 50%;
}
.video_style_box {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.about_us_ratio {
  background-color: #023047;
  padding: 20px;
  margin-bottom: 4rem;
}
.text_white {
  color: #fff;
  text-align: center;
}
.margin_about_us {
  padding-top: 40px;
  padding-bottom: 40px;
  .css-9mgopn-MuiDivider-root {
    border-color: #ffffff;
    border-width: 1px;
  }
}
.about_us_box_two {
  width: 30%;
  height: 200px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 24px;
  //   cursor: pointer;
  text-align: center;
}
.margin_zero {
  margin: 15px;
}
.about_us_div_flex_low {
  display: grid;
  padding-bottom: 5rem;
  padding-top: 3rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.about_choose_us {
  height: auto;
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.about_choose_us_div {
  display: flex;
  padding-top: 2rem;
}
.choose_partone {
  height: auto;
  width: 33.3%;
}
.choose_parttwo {
  background-color: #202020;
  height: auto;
  width: 33.3%;
  padding: 2rem 1rem;
}
.choose_partthree {
  height: auto;
  width: 33.3%;
}
.part_onehalf {
  height: auto;
  background-color: #d83030;
  padding: 3rem 2rem;
  .icon_circle {
    display: flex;
    width: 80px;
    background-color: #dd4b4b;
    border-radius: 50%;
    height: 80px;
    align-items: center;
    justify-content: center;
  }
}
.part_twohalf {
  height: auto;
  background-color: #f3a712;
  padding: 3rem 2rem;
  .icon_circle {
    display: flex;
    width: 80px;
    background-color: #f5b231;
    border-radius: 50%;
    height: 80px;
    align-items: center;
    justify-content: center;
  }
}

.icon_heading {
  // font-size: 22px;
  color: #fff;
}
.icon_heading_top {
  margin-top: 3rem;
}
.icon_para {
  font-size: 15px;
  color: #fff;
}
.dotted_desigen {
  border: 1px dotted #fff;
  height: 100%;
  padding: 2rem;
  .para_text {
    color: #ffffff;
    font-size: 40px;
    margin-bottom: 20px;
    text-align: center;
    margin: 0 0 20px 0;
  }
  .div_para {
    color: #bbbbbb;
    font-size: 15px;
    line-height: 27px;
    text-align: center;
  }
  .tick_done_style {
    color: #bbbbbb;
    font-size: 17px;
    margin: 10px 0;
  }
  .tick_para {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
  }
  .css-i4bv87-MuiSvgIcon-root {
    color: #d83030;
    margin-right: 10px;
  }
  .css-vubbuv{
    color: #d83030;
    margin-right: 10px;
  }
  .para_two_button {
    padding: 15px;
    // width: 50%;
    background-color: transparent;
    border: 2px solid #d83030;
    border-radius: 5px;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      background-color: #d83030;
    }
  }
  .button_div {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
  .parttwo_button {
    width: 100%;
    text-align: center;
  }
}
.progress_bar_about {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
}
.about_progress_bar_top {
  // margin-top: 2rem;
  .css-187mznn-MuiSlider-root.Mui-disabled {
    color: #d83030;
  }
  .css-exkjcx.Mui-disabled{
    color: #d83030;
  }
}
.progress_bar_div_center {
  display: flex;
  justify-content: center;
}
.percentage_show {
  display: flex;
  justify-content: space-between;
}
.about_us_ratio_show {
  display: flex;
  margin-top: 2rem;
}
.divided_about_us_screen {
  width: 50%;
  padding: 1rem;
}
.about_us_div_two {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.padding_para {
  padding: 24px;
}
.about_us_divider_hide {
  padding: 1rem 0;
}
