.contact_div {
  height: 220px;
  background-color: #09c3d0;
  padding: 2rem;
}
.contact_text {
  height: 100%;
  display: flex;
  align-items: flex-end;
}
.contact_info {
  height: auto;
  display: flex;
  align-items: center;
  margin: 4rem;
  justify-content: center;
}
.contact_info_detail {
  width: 70%;
  background-color: #09c3d0;
  position: absolute;
  display: flex;
  padding-bottom: 3rem;
}
.contact_divided {
  background-color: #023047;
  height: 550px;
  width: 300px;
  z-index: 11;
  padding: 2rem;
  position: relative;
  left: -35%;
}

.contact_info_text {
  color: #fff;
  font-size: 1.5rem;
}
.contact_icon {
  display: flex;
  flex-direction: column;
  .css-i4bv87-MuiSvgIcon-root {
    color: #fff;
    font-size: 3rem;
  }
  .css-vubbuv {
    color: #fff;
    font-size: 3rem;
  }
  .contect_icon_show {
    display: flex;
    margin-top: 1rem;
  }
  .icon_para {
    font-size: 14px;
    color: #fff;
    margin: 0;
    line-height: 1.4rem;
    margin-left: 15px;
  }
}
.contact_input_field {
  width: 33%;
  height: 100%;
}
.input_contect_form {
  margin-left: 3rem;
  margin-top: 4rem;
  width: 67%;
}
.icon_para_input {
  font-size: 18px;
  color: #fff;
  margin: 0;
  line-height: 1.4rem;
}
.input_contact_margin {
  margin-bottom: 2rem;
}
.space_contact_show {
  width: 90%;
  display: flex;
  // justify-content: space-between;
  margin-top: 2rem;
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    color: #fff;
  }
  .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    color: #fff;
  }
  .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: #023047;
  }
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: #023047;
  }
  .css-igs3ac {
    border-color: #023047;
  }
  .css-ittuaa-MuiInputAdornment-root {
    color: #fff;
  }
  .css-vubbuv {
    color: #fff;
  }
  .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
    color: #fff;
  }
}
.message_error {
  font-size: 10px;
  text-align: start;
  color: #b80000;
  padding: 8px;
}
.contact_areatext {
  background-color: #09c3d0;
  border-radius: 5px;
  border: 1px solid #023047;
  padding: 1rem;
  color: #fff;
  outline: none;
  &::placeholder {
    color: #fff;
  }
}
.contact_button {
  width: 20%;
  padding: 10px;
  background-color: #023047;
  color: #fff;
  margin-top: 2rem;
  border: 2px solid #023047;
  cursor: pointer;
  &:hover {
    background-color: #09c3d0;
    border: 2px solid #fff;
  }
}
.text_field_contact {
  color: #fff;
  width: 90%;
}
.contact_us_margin_show {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.contact_margin_space {
  .css-1wc848c-MuiFormHelperText-root {
    color: #8b0000;
  }
}
